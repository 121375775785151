import React, { Fragment, useEffect } from 'react'
// import { Redirect } from '@reach/router'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

const Ofertas = (data) => {
  const url = typeof window !== 'undefined' && window.localStorage.getItem('OFERTAS_SEMANA') ? `/lojas/${window.localStorage.getItem('OFERTAS_SEMANA')}/` : '/lojas/'
  useEffect(() => {
    window.location.href = `${data.data.site.siteMetadata.siteUrl}${url}`
  }, [])
  return (
    <>
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <title>Ofertas - Dia Supermercados</title>
        <meta name="description" content="Conheça nossas Ofertas" />
        <meta name="keywords" content="ofertas dia, dia supermercado" />
        <meta name="author" content="DIA Supermercados" />
      </Helmet>
      <Fragment>
        <div style={{
          display: 'block',
          height: 'calc(100vh - 100px)',
          clear: 'both'
        }}>
        </div>
        {/* <Redirect to={url} noThrow /> */}
        <h1>&nbsp;</h1>
      </Fragment>
    </>
  )
}

export default Ofertas

export const query = graphql`
  query {
    site:site {
      siteMetadata {
        siteUrl
        facebookAppId
        author
        title
        siteName
        tagManagerId
        facebookAPIVersion
      }
    }
  }
`
